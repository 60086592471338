.map-container {
  position: relative;
  height: 1100px;
  width: 80%;
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loading {
  z-index: 100;
  height: 1100px;
  width: 80%;
  box-shadow: inset 2500px 0 0 0 rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 1);
}

.map-source-options {
  right: 2rem;
}

.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

h6 {
  font-weight: 900;
  font-family: 'Open Sans Light', sans-serif;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

h5 {
  font-weight: 900;
  font-family: 'Open Sans Light', sans-serif;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.p1 {
  font-size: 103%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

/* .mapboxgl-canvas {
  left: 0;
} */
